
import './App.css';
import AsciiComponent from './ascii'

function App() {
  return ( 
    <div className='App'>
      <AsciiComponent/>
      <p> <a href="https://github.com/f1lth">swe</a> interested in ai, aesthetics, architecture, trading, currently @ stealth</p>
      <div className='content'>
        <h2>Work</h2>
        <div className='container'>
          <ul> 
          <li><a href="https://astrace-frontend-demo.vercel.app/search/">Minerva: </a> Simplify doctor's lives with RAG and LLM document processing</li>
          <li><a href="https://icaps23.icaps-conference.org/program/tutorials/model/">LOCM: </a> Model acquisition <a href="https://ojs.aaai.org/index.php/ICAPS/article/view/13476/13325">algorithm</a> implementation, <a href="https://github.com/AI-Planning/macq/blob/main/macq/extract/locm.py"> open source</a> contrib and demo'd at ICAPS23' Prague</li>
          <li><a href="https://github.com/f1lth/noclip">noclip: </a> Lightweight pip cross-machine clipboard</li>
          </ul>
         </div>
        </div>
        <div className='content'>
          <h2>Contact</h2>
          <ul>
            <li>root@f1lth.ca</li>
          </ul>
          
        </div>
    </div>
  );
}

export default App;
